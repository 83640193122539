import React from 'react';

import { ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';

import { legacyTheme } from '@hbf/dsl/legacy';

import { createRequiredContext } from 'ha/core/RequiredContext';

import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';

import {
  LegacySearchAlertDialog,
  DialogType,
} from '../../../Search/components/SearchAlerts/LegacySearchAlertDialog';
import { LoadableSearchAlertsDrawerWithoutAuth } from '../../../Search/redesign/components/SearchAlertsDrawerWithoutAuth';

export const SearchAlertContext = createRequiredContext<{
  searchAlertOpen: boolean;
  setSearchAlertOpen: (value: boolean) => void;
}>('SearchAlertContext');

export const SearchAlertProvider = (
  { children }: React.PropsWithChildren<unknown> = { children: null },
) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { enableSearchAlertsWithoutAuth } = useFeatureFlags();
  const isSearchAlertsDrawerExperimentEnabled =
    !isAuthenticated && isEnabled(enableSearchAlertsWithoutAuth);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const [dialogType, setDialogType] = React.useState<DialogType>();

  const searchAlertOpen = dialogType === 'main';

  const handleSetSearchAlertOpen = React.useCallback((nextValue: boolean) => {
    setDialogType(nextValue ? 'main' : undefined);
  }, []);

  const value = React.useMemo(
    () => ({
      searchAlertOpen,
      setSearchAlertOpen: isSearchAlertsDrawerExperimentEnabled
        ? () => {
            setIsDrawerOpen(true);
          }
        : handleSetSearchAlertOpen,
    }),
    [
      handleSetSearchAlertOpen,
      searchAlertOpen,
      isSearchAlertsDrawerExperimentEnabled,
    ],
  );

  return (
    <SearchAlertContext.Provider value={value}>
      {children}
      {isSearchAlertsDrawerExperimentEnabled ? (
        <LoadableSearchAlertsDrawerWithoutAuth
          open={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
          }}
        />
      ) : (
        /* todo: replace with new search alert drawer once ready */
        <ThemeProvider theme={legacyTheme}>
          <LegacySearchAlertDialog
            dialogType={dialogType}
            setDialogType={setDialogType}
          />
        </ThemeProvider>
      )}
    </SearchAlertContext.Provider>
  );
};
