/* eslint-disable react/jsx-handler-names */
import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Button, Typography } from '@hbf/dsl/core';

import { Currency } from 'ha/constants/Currencies';

import { useIntl } from 'ha/i18n';
import { RoomsValue, SuitableForValue } from 'ha/types/SearchFilters';

import { SearchFiltersContext } from 'ha/pages/Search/SearchFiltersContext';
import {
  getIsImperialSystem,
  getCountryCode,
  getNumberOfListings,
} from 'ha/pages/Search/selectors';

import { FilterRange } from '../../types';
import { PageDrawer } from '../PageDrawer/PageDrawer';
import { SearchFilter } from '../SearchFilter/SearchFilter';
import { LoadableSearchFilterAdvertiserRating } from '../SearchFilterAdvertiserRating/SearchFilterAdvertiserRating.lazy';
import { LoadableSearchFilterAmenities } from '../SearchFilterAmenities/SearchFilterAmenities.lazy';
import { LoadableSearchFilterContractType } from '../SearchFilterContractType/SearchFilterContractType.lazy';
import { LoadableSearchFilterFacilities } from '../SearchFilterFacilities/SearchFilterFacilities.lazy';
import { LoadableSearchFilterFurniture } from '../SearchFilterFurniture/SearchFilterFurniture.lazy';
import { LoadableSearchFilterGender } from '../SearchFilterGender/SearchFilterGender.lazy';
import { LoadableSearchFilterOccupation } from '../SearchFilterOccupation/SearchFilterOccupation.lazy';
import { LoadableSearchFilterBillsIncluded } from '../SearchFilterPricing/SearchFilterBillsIncluded.lazy';
import { LoadableSearchFilterCurrency } from '../SearchFilterPricing/SearchFilterCurrency.lazy';
import { LoadableSearchFilterPrice } from '../SearchFilterPricing/SearchFilterPrice.lazy';
import { SearchFilterPricingBlock } from '../SearchFilterPricing/SearchFilterPricingBlock';
import { LoadableSearchFilterPropertyType } from '../SearchFilterPropertyType/SearchFilterPropertyType.lazy';
import { LoadableSearchFilterRegistration } from '../SearchFilterRegistration/SearchFilterRegistration.lazy';
import { SearchFilterRegistrationSection } from '../SearchFilterRegistration/SearchFilterRegistrationSection';
import { LoadableSearchFilterSize } from '../SearchFilterSize/SearchFilterSize.lazy';

import { SearchFiltersDrawerContextInternal } from './SearchFiltersDrawerContext';

const useStyles = makeStyles()(theme => ({
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.utils.spacing('ref/spacing/2'),
  },
}));

interface SearchFiltersDrawerProps {
  onClear: () => void;
}

export const SearchFiltersDrawer = ({ onClear }: SearchFiltersDrawerProps) => {
  const { T, urlResolver } = useIntl();
  const { classes } = useStyles();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLargerThanLg = useMediaQuery(theme.breakpoints.up('lg'));

  const { isOpen, onClose } = SearchFiltersDrawerContextInternal.useContext();

  const isImperial = useSelector(getIsImperialSystem);
  const countryCode = useSelector(getCountryCode);

  const {
    localState,
    priceParams,
    onChangeCurrency,
    onChangeAdvertiserRatings,
    onChangeAmenities,
    onChangeBills,
    onChangeContractType,
    onChangeFacilities,
    onChangeFurniture,
    onChangeGender,
    onChangePropertySize,
    onChangePrice,
    onChangeRegistration,
    onChangeRooms,
    onChangeSuitableFor,
    onChangeTypes,
  } = React.useContext(SearchFiltersContext);

  const filterCurrency = (localState.currency ?? 'EUR') as Currency;
  const updateCurrency = (currency: Currency) => {
    onChangeCurrency(currency);
  };

  const filterPriceInCents = {
    from: localState.price.min ?? undefined,
    to: localState.price.max ?? undefined,
  };
  const updatePrice = ({ from, to }: FilterRange) => {
    onChangePrice({ min: from ?? null, max: to ?? null });
  };

  const filterBillsIncluded = localState.bills;
  const updateBillsIncluded = onChangeBills;

  const filterPropertyType = localState.types;
  const updatePropertyType = onChangeTypes;

  const filterPropertyRooms = localState.rooms.bedroomCount;
  const updatePropertyRooms = (rooms: RoomsValue[]) => {
    onChangeRooms({ bedroomCount: rooms });
  };

  const filterGender = localState.gender;
  const suitableForCouples = localState.suitableFor.includes(
    SuitableForValue.COUPLES,
  );
  const updateGender = onChangeGender;
  const updateSuitableForCouples = (suitable: boolean) => {
    if (suitable) {
      onChangeSuitableFor([
        ...localState.suitableFor,
        SuitableForValue.COUPLES,
      ]);
    } else {
      onChangeSuitableFor(
        localState.suitableFor.filter(
          suitableFor => suitableFor !== SuitableForValue.COUPLES,
        ),
      );
    }
  };

  const filterSize = {
    from: localState.propertySize?.sizeMin,
    to: localState.propertySize?.sizeMax,
  };
  const updateSize = ({ from, to }: FilterRange) => {
    onChangePropertySize({ sizeMin: from, sizeMax: to });
  };

  const filterFurniture = localState.furniture;
  const updateFurniture = onChangeFurniture;

  const filterAdvertiserRating = localState.advRating;
  const updateAdvertiserRating = onChangeAdvertiserRatings;

  const filterFacilities = localState.facilities;
  const updateFacilities = onChangeFacilities;

  const filterAmenities = localState.amenities;
  const updateAmenities = onChangeAmenities;

  const filterOccupation = localState.suitableFor;
  const updateOccupation = onChangeSuitableFor;

  const filterContractType = localState.contractType;
  const updateContractType = onChangeContractType;

  const filterRegistration = localState.registration;
  const updateRegistration = onChangeRegistration;

  const total = useSelector(getNumberOfListings);

  return (
    <PageDrawer open={isOpen} onClose={onClose}>
      <PageDrawer.Header>{T('search.filters.popup.title')}</PageDrawer.Header>

      <PageDrawer.Content>
        <SearchFilter data-test-locator="SearchFilters/Pricing">
          <SearchFilter.Title
            slots={{
              end: (
                <LoadableSearchFilterCurrency
                  value={filterCurrency}
                  onChange={updateCurrency}
                />
              ),
            }}
          >
            {T('search.filters.popup.header.monthly_rent')}
          </SearchFilter.Title>

          <SearchFilterPricingBlock>
            <LoadableSearchFilterPrice
              currency={filterCurrency}
              valueInCents={filterPriceInCents}
              absoluteMaxInCents={priceParams.priceMax}
              absoluteMinInCents={priceParams.priceMin}
              onChange={updatePrice}
              distribution={priceParams.priceDistribution}
            />

            <LoadableSearchFilterBillsIncluded
              value={filterBillsIncluded}
              onChange={updateBillsIncluded}
            />
          </SearchFilterPricingBlock>
        </SearchFilter>

        <SearchFilter data-test-locator="SearchFilters/PropertyType">
          <SearchFilter.Title>
            {T('search.filters.popup.header.property_type')}
          </SearchFilter.Title>

          <LoadableSearchFilterPropertyType
            valueRooms={filterPropertyRooms}
            valueTypes={filterPropertyType}
            onChangeRooms={updatePropertyRooms}
            onChangeTypes={updatePropertyType}
          />
        </SearchFilter>

        <SearchFilter data-test-locator="SearchFilters/Gender">
          <SearchFilter.Title>
            {T('search.filters.popup.header.suitable_for')}
          </SearchFilter.Title>
          <LoadableSearchFilterGender
            value={filterGender}
            valueSuitableForCouples={suitableForCouples}
            onChange={updateGender}
            onChangeSuitableForCouples={updateSuitableForCouples}
          />
        </SearchFilter>

        <SearchFilter data-test-locator="SearchFilters/Size">
          <SearchFilter.Title>
            {T('search.filters.popup.header.size')}
          </SearchFilter.Title>
          <LoadableSearchFilterSize
            units={isImperial ? 'imperial' : 'metric'}
            values={filterSize}
            onChange={updateSize}
          />
        </SearchFilter>

        <SearchFilter data-test-locator="SearchFilters/Furniture">
          <SearchFilter.Title>
            {T('search.filters.popup.header.furniture')}
          </SearchFilter.Title>
          <LoadableSearchFilterFurniture
            value={filterFurniture}
            onChange={updateFurniture}
          />
        </SearchFilter>

        <SearchFilter data-test-locator="SearchFilters/AdvertiserRating">
          <SearchFilter.Title>
            {T('search.filters.popup.header.advertiser_rating')}
          </SearchFilter.Title>
          <LoadableSearchFilterAdvertiserRating
            value={filterAdvertiserRating}
            onChange={updateAdvertiserRating}
          />
        </SearchFilter>

        {isLargerThanLg ? (
          <SearchFilter.SizeContainer className={classes.twoColumns}>
            <SearchFilter
              components={{ sizeContainer: null }}
              data-test-locator="SearchFilters/Facilities"
            >
              <SearchFilter.Title>
                {T('search.filters.popup.header.facilities')}
              </SearchFilter.Title>
              <LoadableSearchFilterFacilities
                value={filterFacilities}
                onChange={updateFacilities}
              />
            </SearchFilter>

            <SearchFilter
              components={{ sizeContainer: null }}
              data-test-locator="SearchFilters/Amenities"
            >
              <SearchFilter.Title>
                {T('search.filters.popup.header.amenities')}
              </SearchFilter.Title>
              <LoadableSearchFilterAmenities
                value={filterAmenities}
                onChange={updateAmenities}
              />
            </SearchFilter>
          </SearchFilter.SizeContainer>
        ) : (
          <React.Fragment>
            <SearchFilter data-test-locator="SearchFilters/Facilities">
              <SearchFilter.Title>
                {T('search.filters.popup.header.facilities')}
              </SearchFilter.Title>
              <LoadableSearchFilterFacilities
                value={filterFacilities}
                onChange={updateFacilities}
              />
            </SearchFilter>

            <SearchFilter data-test-locator="SearchFilters/Amenities">
              <SearchFilter.Title>
                {T('search.filters.popup.header.amenities')}
              </SearchFilter.Title>
              <LoadableSearchFilterAmenities
                value={filterAmenities}
                onChange={updateAmenities}
              />
            </SearchFilter>
          </React.Fragment>
        )}

        <SearchFilter data-test-locator="SearchFilters/Occupation">
          <SearchFilter.Title>
            {T('search.filters.popup.header.occupation')}
          </SearchFilter.Title>
          <LoadableSearchFilterOccupation
            value={filterOccupation}
            onChange={updateOccupation}
          />
        </SearchFilter>

        <SearchFilter data-test-locator="SearchFilters/ContractType">
          <SearchFilter.Title>
            {T('search.filters.popup.header.contract_type')}
          </SearchFilter.Title>
          <SearchFilter.Description>
            {T('search.filters.popup.description.body.contract_type')}
            <Typography
              variant="link/xs"
              component="a"
              target="_blank"
              href={urlResolver.getContractTypesArticleUrl()}
            >
              {T('search.filters.popup.description.cta.contract_type')}
            </Typography>
          </SearchFilter.Description>
          <LoadableSearchFilterContractType
            value={filterContractType}
            onChange={updateContractType}
          />
        </SearchFilter>

        <SearchFilterRegistrationSection countryCode={countryCode}>
          <SearchFilter data-test-locator="SearchFilters/Registration">
            <SearchFilter.Title>
              {T('search.filters.popup.header.registration')}
            </SearchFilter.Title>

            <SearchFilterRegistrationSection.Description
              component={SearchFilter.Description}
            />

            <LoadableSearchFilterRegistration
              value={filterRegistration}
              onChange={updateRegistration}
            />
          </SearchFilter>
        </SearchFilterRegistrationSection>
      </PageDrawer.Content>

      <PageDrawer.Footer>
        <Button variant="outlined" color="neutral" onClick={onClear}>
          {T('search.filters.popup.cta.clear_all')}
        </Button>

        {!isLargerThanMd && (
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            data-test-locator="SearchFilters/ShowResults"
          >
            {total
              ? T(
                  'search.filters.popup.cta.show_%s_places',
                  total > 1000 ? '1000+' : total,
                )
              : T('search.filters.popup.cta.no_results.show_places')}
          </Button>
        )}
      </PageDrawer.Footer>
    </PageDrawer>
  );
};

// eslint-disable-next-line import/no-default-export
export default SearchFiltersDrawer;
