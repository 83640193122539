import { createAction } from 'redux-actions';
import { reportError } from 'ha/helpers/bugReporter/reportError';
import { getCurrentLanguageCode } from 'ha/modules/LanguageSwitcher/selectors';
import { Actions, DefaultRadius } from '../constants';
import { getCitiesNearbyRequestId, getSearchParams } from '../selectors';

const flow = {
  start: createAction(Actions.LOAD_CITIES_NEARBY_START),
  done: createAction(Actions.LOAD_CITIES_NEARBY_DONE),
};

const loadCitiesNearby = () => (dispatch, getState, services) => {
  const state = getState();
  const searchParams = getSearchParams(state);

  const params = {
    query: searchParams.citySearch,
    minListings: searchParams.cityMinRooms,
    minPopulation: searchParams.cityMinPopulation,
    lang: getCurrentLanguageCode(state),
    radius: DefaultRadius,
    excludeQuery: true,
  };

  const requestId = Date.now();

  dispatch(flow.start(requestId));

  return services.apiV2
    .getCitiesNearby(params)
    .then(response => {
      if (getCitiesNearbyRequestId(getState()) !== requestId) {
        // Query is not the last one. Dismiss results.
        return;
      }

      dispatch(flow.done(response.data.cities));
    })
    .catch(error => {
      reportError(new Error('Failed to load cities', { cause: error }));
      dispatch(flow.done([]));
    });
};
export { loadCitiesNearby };
