import { AlgoliaListing, NormalizedListing } from '@ha/algolia';
import { UniversityInfo } from '@ha/api/v2/types/UniversityInfo';

import { CurrencyEuroRates } from './utils/algoliaQueries/types';

export type LoadSearchResultsData = {
  listings: NormalizedListing<AlgoliaListing>[];
  mapPins: AlgoliaListingPinSubset[];
  langURLListWithCollections: { [languageCode: string]: string };
  currency: string;
  currencyRates: CurrencyEuroRates;
  pageInfo: {
    offset: number;
    total: number;
    pages: number;
    limit: number;
    isExhaustive: boolean;
  };
  facets: {
    priceMin: number;
    priceMax: number;
    priceAverage: number;
    priceDistribution: number[];
    trimmedPriceMin: number;
    trimmedPriceMax: number;
    trimmedPriceDistribution: unknown[];
    listingsSummary: {
      organic: number;
      partner: number;
      isExhaustive: boolean;
    };
  };
};
export interface SearchPageLoaderData extends LoadSearchResultsData {
  universityInfo?: UniversityInfo;
  cityInfo: UpdateSearchResponse;
}
export type SearchAlgoliaListingParams = Pick<
  AlgoliaListing,
  | 'id'
  | '_geoloc'
  | 'advertiserAvatar'
  | 'propertyType'
  | 'photos'
  | 'photoCount'
  | 'floorPlanCount'
  | 'videoCount'
  | 'creationDateTS'
  | 'dateFrom'
  | 'dateTo'
  | 'internalID'
  | 'minimumStayMonths'
  | 'priceEUR'
  | 'path'
  | 'thumbnailURL'
  | 'city'
  | 'advertiserId'
  | 'exclusivityPartnerIDs'
  | 'facility_bedroom_count'
  | 'facility_bedroom_furnished'
  | 'facility_bedroom_size'
  | 'facility_total_size'
  | 'facility_registration_possible'
  | 'facility_student_housing'
  | 'facility_co_living'
  | 'facility_bed'
  | 'utilities'
  | 'street'
  | 'currentOccupancy'
  | 'cPolicy'
  | 'freePlaces'
  | 'minPrice'
  | 'maxPrice'
  | 'priceType'
  | 'contractType'
  | 'country'
  | 'maximumStay'
  | 'isPartner'
  | 'unitTypePath'
  | 'isMultiUnit'
  | 'unitTypeInternalID'
  | 'rentalPeriodType'
  | 'bookableDateFromStart'
  | 'bookableDateFromEnd'
  | 'bookableDateToStart'
  | 'bookableDateToEnd'
  | 'advertiserFirstName'
  | 'advertiserMoveInOverallRating'
  | 'advertiserMoveInReviewCount'
  | 'isExcellentAdvertiser'
  | 'pricingValues'
  | 'pricingValuesEUR'
  | 'landlordType'
  | 'photo360Count'
  | 'has3DTour'
  | 'rank_withOrpheus'
  | 'rank_withOrpheusExperiment'
>;

export type SearchListing = NormalizedListing<SearchAlgoliaListingParams>;

export type PinListingSubset = Pick<
  AlgoliaListing,
  | 'id'
  | 'priceEUR'
  | 'minPrice'
  | 'priceType'
  | 'unitTypeInternalID'
  | 'country'
  | 'facility_student_housing'
  | 'facility_co_living'
>;

export interface MarkerPoint {
  id: string;
  type: 'Feature';
  geometry: {
    type: 'Point';
    coordinates: number[];
  };
  properties: {
    cluster: false;
    rooms: PinListingSubset[];
  };
}

export enum MapViewState {
  noMap = 'noMap',
  halfMap = 'halfMap',
  fullMap = 'fullMap',
}

export interface UpdateSearchResponse {
  city: string;
  cityLocalized: string;
  country: string;
  countryCode: string;
  countryLocalized: string;
  langURLList: { [languageCode: string]: string };
  latitude: number;
  longitude: number;
  minZoomLevel: number;
  radius: number;
  zoomLevel: number;
  geonameId: string;
  currency: string;
}

export type AlgoliaListingPinSubset = Pick<
  AlgoliaListing,
  | '_geoloc'
  | 'priceEUR'
  | 'minPrice'
  | 'priceType'
  | 'internalID'
  | 'unitTypeInternalID'
  | 'country'
  | 'facility_student_housing'
  | 'facility_co_living'
>;
