import { useSelector } from 'react-redux';

import { City } from '@ha/api/v2/types';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useServices } from 'ha/services';

import { getCurrentLanguageCode } from 'ha/modules/LanguageSwitcher/selectors';

const useSearchCity = () => {
  const { apiV2 } = useServices();
  const lang = useSelector(getCurrentLanguageCode);

  const getSearchedCity = (place: string): Promise<City | null> => {
    return apiV2
      .searchCity({
        query: place,
        languages: [lang],
      })
      .then(({ data }) => data[lang])
      .catch(error => {
        reportError(new Error('searchCity api error', { cause: error }));
        return null;
      });
  };

  return getSearchedCity;
};
export { useSearchCity };
