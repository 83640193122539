import { getSearchParams, getBounds } from '../selectors';

import { updateSearchParams, setPreviousParams } from './actions';
import { loadSearchResults } from './loadSearchResults';
import { saveSearch } from './saveSearch';

const load = (reqParams, cityInfo, rates) => (dispatch, getState) => {
  dispatch(updateSearchParams(reqParams));
  const shouldUseMap = Boolean(
    reqParams.lLng && reqParams.rLng && reqParams.tLat && reqParams.bLat,
  );
  const state = getState();
  const searchParams = getSearchParams(state);

  const mapBounds = getBounds(state);

  const searchParamsWithMapBounds = {
    ...searchParams,
    ...(shouldUseMap && {
      ...mapBounds,
    }),
  };

  return dispatch(
    loadSearchResults({
      reqParams: searchParamsWithMapBounds,
      localizedKind: reqParams.localizedKind,
      priceAverage: reqParams.priceAverage,
      cityInfo: cityInfo,
      currencyEuroRates: rates,
    }),
  )
    .then(data => {
      dispatch(saveSearch());
      dispatch(setPreviousParams(searchParamsWithMapBounds));

      return Promise.resolve(data);
    })
    .catch(error => Promise.reject(error));
};

export { load };
