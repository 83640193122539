import React from 'react';

import { Typography } from '@hbf/dsl/core';

import { useIntl } from 'ha/i18n';

import { PageSize } from 'ha/pages/Search/constants';

import { SearchControlsAlertButton } from '../SearchControlsAlert/SearchControlsAlertButton';

import { SearchResultsGuideBase } from './SearchResultsGuideBase';

export const EXPECTED_RESULTS_COUNT = PageSize - 1;

interface Props {
  resultsCount: number;
}

const SearchResultsFewerThanExpected: React.FC<Props> = ({ resultsCount }) => {
  const { T } = useIntl();

  if (resultsCount <= 0 || resultsCount >= EXPECTED_RESULTS_COUNT) return null;

  return (
    <SearchResultsGuideBase
      slots={{
        header: (
          <React.Fragment>
            <Typography
              variant="heading/mobile/h3-semibold"
              data-test-locator="SearchResultsEmpty/Title"
            >
              {T('search_results.fewer_than_6.heading')}
            </Typography>

            <Typography
              variant="body/sm-regular"
              data-test-locator="SearchResultsEmpty/Description"
            >
              {T('search.page.description.no_results.too_many_filters')}
            </Typography>
          </React.Fragment>
        ),
        createAlertButton: (
          <SearchControlsAlertButton
            customEventProps={{ eventOriginated: 'Search with few results' }}
          />
        ),
      }}
    />
  );
};

export { SearchResultsFewerThanExpected };
