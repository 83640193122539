import React from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Language } from '@ha/intl';
import { removeURLSearchParams } from '@ha/search-query';

import { CanonicalCollections } from 'ha/constants/SearchPageCollections';

import { useIntl } from 'ha/i18n';
import { notEmpty } from 'ha/utils/notEmpty';

import {
  getCityName,
  getCollectionFilter,
  getIsAdvertiserSearch,
  getAdvertiserName,
} from 'ha/pages/Search/selectors';
import { SearchPageBreadcrumbs as Breadcrumbs } from 'ha/pages/SearchRedesign/components/SearchResultsBreadcrumbs/SearchResultsBreadcrumbs';

const SearchPageBreadcrumbs: React.FC = () => {
  const { T, urlResolver, lang } = useIntl();
  const params = useParams();

  const city = useSelector(getCityName);
  const canonicalCollection = useSelector(getCollectionFilter);
  const isAdvertiserSearch = useSelector(getIsAdvertiserSearch);
  const advertiserName = useSelector(getAdvertiserName);
  const [urlSearchParams] = useSearchParams();
  const urlSearchParamsStr = removeURLSearchParams({
    urlSearchParams,
    itemsToRemove: ['page'],
  }).toString();

  const countryAndCityPageLinks = React.useMemo(() => {
    const whitelistedCities = [
      'Berlin',
      'Hamburg',
      'Frankfurt am Main',
      'Munich',
    ];

    return lang === Language.en && whitelistedCities.includes(city);
  }, [city, lang]);

  let breadcrumbsCategory;
  let categorySearchURL;

  if (canonicalCollection) {
    breadcrumbsCategory = {
      [CanonicalCollections.ApartmentForRent]: T(
        'seo_search_breadcrumb_apartment_for_rent',
      ),
      [CanonicalCollections.PrivateRoomForRent]: T(
        'seo_search_breadcrumb_private_room_for_rent',
      ),
      [CanonicalCollections.SharedRoomForRent]: T(
        'seo_search_breadcrumb_shared_room_for_rent',
      ),
      [CanonicalCollections.StudioForRent]: T(
        'seo_search_breadcrumb_studio_for_rent',
      ),
      [CanonicalCollections.CheapAccommodation]: T(
        'seo_search_breadcrumb_cheap_accommodation',
      ),
      [CanonicalCollections.FurnishedApartments]: T(
        'seo_search_breadcrumb_furnished_apartments',
      ),
      [CanonicalCollections.StudentAccommodation]: T(
        'seo_search_breadcrumb_student_accommodation',
      ),
      [CanonicalCollections.LongTermRentals]: T(
        'seo_search_breadcrumb_long_term_rentals',
      ),
      [CanonicalCollections.OneBedroomApartments]: T(
        'seo_search_breadcrumb_one_bedroom_apartments',
      ),
      [CanonicalCollections.TwoBedroomApartments]: T(
        'seo_search_breadcrumb_two_bedroom_apartments',
      ),
      [CanonicalCollections.StudentRoom]: T(
        'seo_search_breadcrumb_student_room',
      ),
    }[canonicalCollection];

    categorySearchURL = urlResolver.absolutizePath(
      [
        `/s/${params.location}`,
        params.localizedKind ? `/${params.localizedKind}` : '',
        urlSearchParamsStr ? `?${urlSearchParamsStr}` : '',
      ].join(''),
    );
  }

  const mainSearchUrl = urlResolver.absolutizePath(
    [
      `/s/${params.location}`,
      urlSearchParamsStr ? `?${urlSearchParamsStr}` : '',
    ].join(''),
  );

  const breadcrumbs = [
    {
      name: city,
      path: countryAndCityPageLinks
        ? urlResolver.absolutizePath(`/${params.location}`)
        : mainSearchUrl,
    },
    breadcrumbsCategory
      ? {
          name: breadcrumbsCategory,
          path: categorySearchURL,
        }
      : null,
    isAdvertiserSearch && advertiserName
      ? {
          name: advertiserName,
        }
      : null,
  ].filter(notEmpty);

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};

export { SearchPageBreadcrumbs };
