import React from 'react';

import { ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { SearchPageData } from '@ha/contentful';
import { units, Grid, legacyTheme } from '@hbf/dsl/legacy';

import { FeaturedCountries } from 'ha/constants/FeaturedCountries';

import { useFeatureFlags } from 'ha/modules/FeatureFlags';

import { useOptionalHermes } from 'ha/modules/Hermes';
import { KamernetBanner } from 'ha/modules/KamernetBanner';

import { SearchCategoriesContainer as SearchCategories } from '../containers/SearchCategories';
import { SearchCollectionSubtitleContainer as SearchCollectionSubtitle } from '../containers/SearchCollectionSubtitle';
import { PaginationContainer as Pagination } from '../redesign/containers/PaginationContainer';
import { getCollectionFilter } from '../selectors';

import { CityInfo as CityFAQ } from './CityFAQ';
import { CityInfo } from './CityInfo';
import { SearchCitiesNearby } from './SearchCitiesNearby';
import { SearchPageBreadcrumbs } from './SearchPageBreadcrumbs';
import { SeoContentAssignable, seoContents } from './SearchSEO';

const BERLIN_CITY_PATHNAME = '/Berlin--Germany';

interface Props {
  cityCanonical?: string;
  countryCode?: string;
  cityLocalizedName?: string;
}

const useStyles = makeStyles()(theme => ({
  seoMargin: {
    margin: units.yx(0.75, 0),
  },
  searchTips: {
    [theme.breakpoints.down('md')]: {
      padding: units.yx(0, 0.5),
    },
  },
}));

const SearchResultsBottomInternal: React.FC<Props> = ({
  cityLocalizedName,
  cityCanonical,
  countryCode,
}) => {
  const { classes, cx } = useStyles();
  const { data } = useOptionalHermes<SearchPageData & SeoContentAssignable>();
  const { searchKamernetBanner } = useFeatureFlags();
  const canonical = useSelector(getCollectionFilter);

  const seoContent = data && data[seoContents[canonical]];
  const showSearchFAQs =
    !seoContent &&
    !canonical &&
    data?.cityPagePathname?.toLowerCase() !==
      BERLIN_CITY_PATHNAME.toLowerCase();

  const isFeaturedCountry = React.useMemo(
    () => FeaturedCountries.some(country => country.code === countryCode),
    [countryCode],
  );

  return (
    <Grid container>
      <Grid item xs={12} className={classes.seoMargin}>
        <Pagination />
      </Grid>

      {searchKamernetBanner === 'on' && (
        <ThemeProvider theme={legacyTheme}>
          <Grid item xs={12}>
            <KamernetBanner
              cityCanonical={cityCanonical || ''}
              cityLocalized={cityLocalizedName || ''}
            />
          </Grid>
        </ThemeProvider>
      )}
      <Grid item xs={12} className={cx(classes.seoMargin, classes.searchTips)}>
        <SearchPageBreadcrumbs />
      </Grid>
      <Grid item xs={12} className={classes.searchTips}>
        <SearchCollectionSubtitle />
      </Grid>
      {showSearchFAQs && data?.searchFaq && data?.searchFaq?.length > 0 && (
        <Grid
          item
          xs={12}
          className={cx(classes.seoMargin, classes.searchTips)}
        >
          <CityFAQ faq={data?.searchFaq} />
        </Grid>
      )}
      {data?.copyText && (
        <Grid
          item
          xs={12}
          className={cx(classes.seoMargin, classes.searchTips)}
        >
          <CityInfo text={data.copyText} />
        </Grid>
      )}
      {!isFeaturedCountry && (
        <React.Fragment>
          <Grid
            item
            xs={12}
            className={cx(classes.seoMargin, classes.searchTips)}
          >
            <SearchCategories />
          </Grid>
          <SearchCitiesNearby />
        </React.Fragment>
      )}
    </Grid>
  );
};

export const SearchResultsBottom = React.memo(SearchResultsBottomInternal);
