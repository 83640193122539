export { SearchCityAbout } from './SearchCityAbout';
export { SearchCityLinks } from './SearchCityLinks';

export const seoContents = {
  '': 'seoContent',
  'private-rooms': 'seoContentPrivateRooms',
  'shared-rooms': 'seoContentSharedRooms',
  'apartment-for-rent': 'seoContentApartmentForRent',
  'studio-for-rent': 'seoContentStudioForRent',
  'furnished-apartments': 'seoContentFurnishedApartments',
  'cheap-accommodation': 'seoContentCheapAccommodation',
  'student-accommodation': 'seoContentStudentAccommodation',
  'long-term-rentals': 'seoContentLongTermRentals',
  'two-bedroom-apartments': 'seoContentTwoBedroomApartments',
  'student-room': 'seoContentStudentRoom',
  'one-bedroom-apartments': 'seoContentOneBedroomApartments',
};

export interface SeoContentAssignable {
  seoContent: string;
  seoContentPrivateRooms: string;
  seoContentSharedRooms: string;
  seoContentApartmentForRent: string;
  seoContentStudioForRent: string;
  seoContentFurnishedApartments: string;
  seoContentCheapAccommodation: string;
  seoContentStudentAccommodation: string;
  seoContentLongTermRentals: string;
  seoContentTwoBedroomApartments: string;
  seoContentStudentRoom: string;
  seoContentOneBedroomApartments: string;
}
