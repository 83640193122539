import { createAction } from 'redux-actions';

import { MultipleQueriesResponse } from '@algolia/client-search';
import get from 'lodash-es/get';
import last from 'lodash-es/last';
import mapValues from 'lodash-es/mapValues';
import { Dispatch } from 'redux';

import { AlgoliaListing, normalizeListing } from '@ha/algolia';
import { City } from '@ha/api/v2/types';
import { Language } from '@ha/intl';

import { DEFAULT_CURRENCY } from 'ha/constants/Currencies';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { getFeatureFlags } from 'ha/modules/FeatureFlags';
import { AppServices } from 'ha/services/getAppServices';
import { GlobalState } from 'ha/types/store';
import { getTranslatedCollection } from 'ha/utils/urls/getTranslatedCollection';

import {
  CurrencyEuroRates,
  ReqParams,
  SearchParams,
} from 'ha/pages/Search/utils/algoliaQueries/types';

import {
  Actions,
  DefaultDistributionSteps,
  DefaultDistributionTrimPercentage,
} from '../constants';
import {
  getSearchRequestId,
  getCurrentIndex,
  getIsImperialSystem,
  getMapViewState,
} from '../selectors';
import { getFilterBuilderContext } from '../selectors/algoliaSelectors';
import {
  getInitialLatitude,
  getInitialLongitude,
  getRadius,
} from '../selectors/mapSelectors';
import { MapViewState, LoadSearchResultsData } from '../types';
import {
  getAlgoliaListingsQuery,
  getAlgoliaFacetQuery,
  reformatDistribution,
  getDistribution,
  getTrimmedDistribution,
  normalizeMinMaxPrices,
  getAlgoliaPinsQuery,
} from '../utils';

export const flow = {
  start: createAction(Actions.LOAD_START),
  done: createAction(Actions.LOAD_DONE),
  error: createAction(Actions.LOAD_ERROR),
};

const updateFullMapPins = createAction(Actions.LOAD_MAP_PINS_DONE);

export type LoadSearchResultsParams = {
  reqParams: ReqParams;
  localizedKind: string;
  priceAverage: number;
  cityInfo: Partial<City & SearchParams & { city: string }>;
  currencyEuroRates: CurrencyEuroRates;
};

type SearchRequestState = {
  requestId: number;
  withDynamicMinimumPrice: string | undefined;
  mapViewState: MapViewState;
};

function searchResponseHandler(
  reqState: SearchRequestState,
  searchParams: LoadSearchResultsParams,
  response: MultipleQueriesResponse<AlgoliaListing>,
  dispatch: Dispatch,
  state: GlobalState,
): PromiseLike<LoadSearchResultsData> {
  if (getSearchRequestId(state) !== reqState.requestId) {
    // Query is not the last one. Dismiss results.
    return Promise.resolve({
      listings: [],
      mapPins: [],
      langURLListWithCollections: {},
      currency: '',
      currencyRates: {},
      pageInfo: {
        offset: 0,
        total: 0,
        pages: 0,
        limit: 0,
        isExhaustive: false,
      },
      facets: {
        priceMin: 0,
        priceMax: 0,
        priceAverage: 0,
        priceDistribution: [],
        trimmedPriceMin: 0,
        trimmedPriceMax: 0,
        trimmedPriceDistribution: [],
        listingsSummary: {
          organic: 0,
          partner: 0,
          isExhaustive: false,
        },
      },
    });
  }

  const [listings, priceFacet, pins] = response.results;

  const currency =
    searchParams.reqParams.currency ||
    searchParams.cityInfo.currency ||
    DEFAULT_CURRENCY;
  const currencyRate = searchParams.currencyEuroRates[currency];
  const currencyRates = searchParams.currencyEuroRates;

  let min = get(priceFacet.facets_stats, ['priceEUR', 'min'], 0);
  let max = get(priceFacet.facets_stats, ['priceEUR', 'max'], 0);

  if (reqState.withDynamicMinimumPrice === 'on') {
    min = get(priceFacet.facets_stats, ['minPrice', 'min'], 0);
    max = get(priceFacet.facets_stats, ['minPrice', 'max'], 0);
  }

  const { minPrice, maxPrice } = normalizeMinMaxPrices(min, max, currencyRate);

  const priceDistributionArg = {
    facet:
      (reqState.withDynamicMinimumPrice === 'on'
        ? priceFacet.facets?.minPrice
        : priceFacet.facets?.priceEUR) || [],
    min: minPrice,
    max: maxPrice,
    rate: currencyRate,
    steps: DefaultDistributionSteps,
    trimPercentage: DefaultDistributionTrimPercentage,
  };

  const priceDistribution = reformatDistribution(
    getDistribution(priceDistributionArg),
  ) as number[];
  const priceMin = minPrice * 100;
  const priceMax = maxPrice * 100;
  const trimmedPriceDistribution = reformatDistribution(
    getTrimmedDistribution(priceDistributionArg),
  ) as unknown[];
  const trimmedPriceMin = priceMin;
  // eslint-disable-next-line
  const trimmedPriceMax =
    (last(trimmedPriceDistribution) as { range: number }).range * 100;

  const langURLListWithCollections = mapValues(
    // eslint-disable-next-line
    (searchParams.cityInfo as any).langURLList,
    (url, lang: Language) => {
      // eslint-disable-next-line
      const translatedCollection = getTranslatedCollection(
        lang,
        searchParams.localizedKind,
      );
      return `${url}${translatedCollection ? `/${translatedCollection}` : ''}`;
    },
  );

  dispatch(
    flow.done({
      ...searchParams.cityInfo,
      langURLListWithCollections,
      currency,
      defaultCurrency: searchParams.cityInfo.currency,
      edges: listings.hits.map(normalizeListing({ currency, currencyRate })),
      pageInfo: {
        offset: listings.page,
        total: listings.nbHits,
        pages: listings.nbPages,
        limit: listings.hitsPerPage,
        isExhaustive: Boolean(listings.exhaustiveNbHits),
      },
      listingsCount: listings.nbHits,
      currencyRates,
      priceMin,
      priceMax,
      priceAverage: searchParams.priceAverage,
      priceDistribution,
      trimmedPriceMin,
      trimmedPriceMax,
      trimmedPriceDistribution,
      listingsSummary: {
        organic: get(listings, ['facets', 'isPartner', 'false'], 0) as number,
        partner: get(listings, ['facets', 'isPartner', 'true'], 0) as number,
        isExhaustive: get(listings, ['exhaustiveFacetsCount'], true),
      },
    }),
  );

  if (reqState.mapViewState === MapViewState.fullMap) {
    // dispatch full map pins only
    dispatch(updateFullMapPins({ pins: pins.hits }));
  }

  return Promise.resolve({
    listings: listings.hits.map(normalizeListing({ currency, currencyRate })),
    mapPins: reqState.mapViewState === MapViewState.fullMap ? pins.hits : [],
    langURLListWithCollections,
    currency,
    currencyRates,
    pageInfo: {
      offset: listings.page,
      total: listings.nbHits,
      pages: listings.nbPages,
      limit: listings.hitsPerPage,
      isExhaustive: Boolean(listings.exhaustiveNbHits),
    },
    facets: {
      priceMin,
      priceMax,
      priceAverage: searchParams.priceAverage,
      priceDistribution,
      trimmedPriceMin,
      trimmedPriceMax,
      trimmedPriceDistribution,
      listingsSummary: {
        organic: get(listings, ['facets', 'isPartner', 'false'], 0) as number,
        partner: get(listings, ['facets', 'isPartner', 'true'], 0) as number,
        isExhaustive: get(listings, ['exhaustiveFacetsCount'], true),
      },
    },
  });
}

export const loadSearchResults = (params: LoadSearchResultsParams) => {
  return (
    dispatch: Dispatch,
    getState: () => GlobalState,
    services: AppServices,
  ) => {
    const requestId = Date.now();

    dispatch(flow.start(requestId));

    const state = getState();
    const mapViewState = getMapViewState(state);
    const initialLatitude = getInitialLatitude(state);
    const initialLongitude = getInitialLongitude(state);
    const searchMapRadius = getRadius(state);

    const { withDynamicMinimumPrice } = getFeatureFlags(state);

    // index chosen based on master variants and FFs inside the selector
    const indexName = getCurrentIndex(state);

    const isImperialSystem = getIsImperialSystem(state);

    const sp: { city: string; countryCode: string } = {
      city: params.cityInfo.city!,
      countryCode: params.cityInfo.countryCode!,
    };

    // we invoke those queries to have the single source of truth
    const queries = [
      // Algolia – Main Query for fetching the listing and information for the listing results
      getAlgoliaListingsQuery(
        params.reqParams,
        params.cityInfo as SearchParams,
        params.currencyEuroRates,
        getFilterBuilderContext(state, 'listings', sp),
        isImperialSystem,
        { withoutFacets: true },
      ),
      // Algolia – Fetch count of listing per price bracket for composing the price histogram
      getAlgoliaFacetQuery(
        withDynamicMinimumPrice === 'on' ? ['minPrice'] : ['priceEUR'],
        params.reqParams,
        params.cityInfo as SearchParams,
        params.currencyEuroRates,
        getFilterBuilderContext(state, 'priceFacet', sp),
      ),
    ];

    if (mapViewState === MapViewState.fullMap) {
      const useMapBounds =
        params.reqParams.topLat &&
        params.reqParams.bottomLat &&
        params.reqParams.leftLng &&
        params.reqParams.rightLng;

      queries.push(
        // Algolia – fetch pins for the full map view
        getAlgoliaPinsQuery(
          params.reqParams,
          // On the first render (inside SSR), the map has not been initialized yet; thus we use the default lat, lng,
          // and radius that comes from the search city API. After the map is initialized, we filter based on the bounding box.
          useMapBounds
            ? {
                latitude: initialLatitude,
                longitude: initialLongitude,
                radius: searchMapRadius,
              }
            : (params.cityInfo as SearchParams),
          params.currencyEuroRates,
          getFilterBuilderContext(state, 'listings', {
            city: params.cityInfo.cityCanonical!,
            countryCode: params.cityInfo.countryCode!,
          }),
        ),
      );
    }

    const srp: SearchRequestState = {
      requestId,
      withDynamicMinimumPrice,
      mapViewState,
    };

    // eslint-disable-next-line
    return services.algolia
      .searchListings(queries, indexName)
      .then(r => searchResponseHandler(srp, params, r, dispatch, state))
      .catch(error => {
        reportError(new Error('Load search results failed', { cause: error }));
        dispatch(flow.error());
      });
  };
};
