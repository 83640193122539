import React from 'react';

import { useSelector } from 'react-redux';

import { Fab } from '@hbf/dsl/core';
import { Place, PlaceSlash } from '@hbf/icons/brand-fill';

import { useIntl } from 'ha/i18n';

import { useMapViewHandler } from 'ha/pages/Search/hooks/useMapViewHandler';
import { getMapViewState } from 'ha/pages/Search/selectors';
import { MapViewState } from 'ha/pages/Search/types';

export const SearchMapChipToggle = () => {
  const { T } = useIntl();

  const currentView = useSelector(getMapViewState);

  const { changeMapView } = useMapViewHandler();

  return (
    <Fab
      color="tertiary"
      size="md"
      startIcon={
        currentView === MapViewState.noMap ? <Place /> : <PlaceSlash />
      }
      onClick={() => {
        const nextView =
          currentView === MapViewState.fullMap
            ? MapViewState.noMap
            : MapViewState.fullMap;
        changeMapView(nextView);
      }}
    >
      {currentView === MapViewState.noMap
        ? T('search_page.cta.open_map')
        : T('search_page.cta.hide_map')}
    </Fab>
  );
};
