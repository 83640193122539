import { track } from '@hbf/analytics';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { Thunk } from 'ha/myredux/types';
import {
  SearchAlert,
  SearchAlertChannel,
  SearchAlertFrequency,
} from 'ha/services/bell';
import { DefaultTimezone, getTimezone } from 'ha/utils/getTimezone';

import {
  getSearchAlert,
  getSearchAlertParams,
} from '../selectors/searchAlertSelectors';

import { UpsertActions } from './searchAlertActions';

const upsertSearchAlert =
  (
    title?: string,
    frequency?: SearchAlertFrequency,
    channel?: SearchAlertChannel,
    filterLast24Hours?: boolean,
  ): Thunk =>
  (dispatch, getState, services) => {
    const state = getState();

    dispatch(UpsertActions.start());

    const timezone = getTimezone() ?? DefaultTimezone;

    const searchAlert = getSearchAlert(state);
    const searchAlertParams = getSearchAlertParams(state);

    if (filterLast24Hours) {
      searchAlertParams.filters.recentlyAdded = '1-day';
    }

    let upsertBellPromise: Promise<SearchAlert> | null = null;

    const apiCallParams = {
      ...searchAlertParams,
      timezone,
      alias: title || searchAlertParams.alias,
      channel: channel || 'email',
      frequency: frequency || 'daily',
    };

    if (!searchAlert) {
      upsertBellPromise = services.bell.createSearchAlert(apiCallParams);
    } else {
      upsertBellPromise = services.bell.editSearchAlert({
        alertID: searchAlert.alertID,
        ...apiCallParams,
      });
    }

    return upsertBellPromise
      .then(bellResponse => {
        dispatch(
          UpsertActions.done({
            searchAlert: bellResponse,
          }),
        );

        track('Search alert updated', {
          listingcity: searchAlertParams.city,
        });
      })
      .catch(error => {
        reportError(new Error('UpsertSearchResult error', { cause: error }));
        dispatch(UpsertActions.error());
      });
  };

export { upsertSearchAlert };
