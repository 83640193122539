import { BuilderContext } from '@ha/algolia';
import { BrandingType } from '@ha/api/v2/types/UniversityInfo';

import { excludedCities } from 'ha/constants/studapart';

import { getUser, getUserInformation } from 'ha/modules/AuthLogic/selectors';
import { getFeatureFlags, isEnabled } from 'ha/modules/FeatureFlags';
import { GlobalState } from 'ha/types/store';

import { getIsWithRelevantAvailabilityEnabled } from './filterSelectors';

interface SearchParams {
  city: string;
  countryCode: string;
}

export const getFilterBuilderContext = (
  state: GlobalState,
  queryType:
    | 'listings'
    | 'priceFacet'
    | 'propertyTypeFacet'
    | 'propertySizeFacet',
  searchParams?: SearchParams,
): BuilderContext => {
  // add experiments and feature flags regarding algolia query here

  const {
    withDynamicMinimumPrice,
    searchPartnerSorting,
    strictRentalPeriodSearch,
    excludeStudapart,
  } = getFeatureFlags(state);

  const user = getUser(state);
  const userInformation = getUserInformation(state);

  const withRelevantAvailability = getIsWithRelevantAvailabilityEnabled(state);

  return {
    withoutPrice: queryType === 'priceFacet',
    withoutPropertyType: queryType === 'propertyTypeFacet',
    withDynamicMinimumPrice: isEnabled(withDynamicMinimumPrice),
    withoutPropertySize: queryType === 'propertySizeFacet',
    withPartnerSorting: isEnabled(searchPartnerSorting),
    excludeStudapart:
      isEnabled(excludeStudapart) &&
      (searchParams?.countryCode ?? '').toLowerCase() === 'fr' &&
      excludedCities.includes(searchParams?.city || ''),
    withSrpDates: isEnabled(strictRentalPeriodSearch),

    // https://housinganywhere.atlassian.net/browse/TX-1663
    withRelevantAvailability,
    uniId:
      user?.uniId &&
      userInformation?.partner?.brandingType === BrandingType.CoBranded
        ? user.uniId
        : undefined,
  };
};
