import { UniversityInfo } from '@ha/api/v2/types/UniversityInfo';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { AppServices } from 'ha/services/getAppServices';

export async function loadUniversityInfo(
  services: AppServices,
  uniId: number,
): Promise<UniversityInfo | undefined> {
  try {
    const data = await services.apiV2.getUniversityInformation(uniId);
    if (data.status) {
      return data.data;
    }
    return undefined;
  } catch (error) {
    reportError(
      new Error('Failed to fetch university information', { cause: error }),
    );

    // We don't want to throw any error, as the page should still be rendered even if this API call fails
    return undefined;
  }
}
