import React from 'react';

import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { defineKey } from '@ha/intl';
import { Typography, units } from '@hbf/dsl/core';
import { Container, Grid, Link } from '@hbf/dsl/legacy';

import { CanonicalCollectionTypes } from 'ha/constants/SearchPageCollections';

import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { useIntl } from 'ha/i18n';
import { TypeValue } from 'ha/types/SearchFilters';

import { sidebarWidth } from '../../constants';
import { getCountryLocalized, getCityLocalized } from '../../selectors';

type LinkType = {
  label: string;
  category: CanonicalCollectionTypes;
};

export const useStyles = makeStyles()(theme => ({
  sidePadding: {
    width: units.pxToRem(sidebarWidth),
    marginRight: units.rem(1.5),
  },
  linksContainer: {
    marginBottom: units.pxToRem(32),
  },
  linkTitle: {
    marginBottom: units.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      fontSize: units.pxToRem(18),
    },
  },
  links: {
    marginTop: units.pxToRem(16),
  },
  link: {
    textDecoration: 'underline !important',
  },
  linksSectionContainer: {
    border: `1px solid ${theme.palette.grey['200']}`,
    padding: units.pxToRem(16),
    borderRadius: units.pxToRem(4),
    width: '45%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export const SearchCityLinks: React.FC = () => {
  const { classes } = useStyles();
  const { md } = useMediaQuery();
  const city = useSelector(getCityLocalized) ?? '';
  const country = useSelector(getCountryLocalized) ?? '';

  const { T, urlResolver } = useIntl();

  const popularSearchesLinks: LinkType[] = [
    {
      label: defineKey('seo.popular_searches.apartments'),
      category: TypeValue.APARTMENT,
    },
    {
      label: defineKey('seo.popular_searches.studios'),
      category: TypeValue.STUDIO,
    },
    {
      label: defineKey('seo.private_room_for_rent'),
      category: TypeValue.PRIVATE_ROOM,
    },

    {
      label: defineKey('seo.popular_searches.shared_rooms'),
      category: TypeValue.SHARED_ROOM,
    },

    {
      label: defineKey('seo.popular_searches.1_bedroom_apt'),
      category: 'one-bedroom-apartments',
    },

    {
      label: defineKey('seo.popular_searches.2_bedroom_apt'),
      category: 'two-bedroom-apartments',
    },
  ];

  const usefulLinks: LinkType[] = [
    {
      label: defineKey('seo.useful_links.furnished_apts'),
      category: 'furnished-apartments',
    },

    {
      label: defineKey('seo.useful_links.long_term'),
      category: 'long-term-rentals',
    },

    {
      label: defineKey('seo.useful_links.student_housing'),
      category: 'student-accommodation',
    },
    {
      label: defineKey('seo.useful_links.student_rooms'),
      category: 'student-room',
    },
    {
      label: defineKey('seo.useful_links.cheap_accommodation'),
      category: 'cheap-accommodation',
    },
  ];

  return (
    <div className={classes.linksContainer}>
      <Container>
        <Grid container wrap="nowrap">
          {md && <div className={classes.sidePadding} />}
          <Grid container item xs flexDirection="column">
            <Typography
              component="h3"
              variant="heading/mobile/h3-regular"
              className={classes.linkTitle}
              fontWeight="bold"
            >
              {T('seo.popular_searches_in_city_country', city, country)}
            </Typography>
            <Grid container item xs gap={1} alignItems="flex-start">
              <div className={classes.linksSectionContainer}>
                <Typography variant="body/sm-semibold">
                  {T('seo.popular_searches')}
                </Typography>
                <Grid
                  container
                  item
                  xs
                  flexDirection="column"
                  className={classes.links}
                  gap={2}
                >
                  {popularSearchesLinks.map(link => {
                    return (
                      <Link
                        key={link.label}
                        className={classes.link}
                        to={urlResolver.getSearchUrl(
                          city,
                          country,
                          undefined,
                          link.category,
                        )}
                      >
                        {T(link.label, city)}
                      </Link>
                    );
                  })}
                </Grid>
              </div>
              <div className={classes.linksSectionContainer}>
                <Typography variant="body/sm-semibold">
                  {T('seo.useful_links')}
                </Typography>
                <Grid
                  container
                  item
                  xs
                  flexDirection="column"
                  className={classes.links}
                  gap={2}
                >
                  {usefulLinks.map(link => {
                    return (
                      <Link
                        key={link.label}
                        className={classes.link}
                        to={urlResolver.getSearchUrl(
                          city,
                          country,
                          undefined,
                          link.category,
                        )}
                      >
                        {T(link.label, city)}
                      </Link>
                    );
                  })}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
