import React, { useState, useEffect } from 'react';

import { Theme } from '@mui/material';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { makeStyles } from 'tss-react/mui';

import { Alert, units } from '@hbf/dsl/core';
import { Link } from '@hbf/dsl/legacy';
import { Calendar } from '@hbf/icons/streamline-bold';

import { Cookies } from 'ha/constants/Cookies';

import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { useIntl } from 'ha/i18n';

import { useCookie } from 'ha/pages/Search/hooks/useCookie';
import { getDateFilterValue } from 'ha/pages/Search/selectors/filterSelectors';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    marginBottom: units.rem(1),
  },
  icon: {
    color: theme.palette.grey[600],
  },
}));

export const SearchResultsNotificationToAddDatesOnFilter: React.FC = () => {
  const { T } = useIntl();
  const { md } = useMediaQuery();
  const { classes } = useStyles();

  const [cookie, updateCookie] = useCookie(Cookies.USE_DATES_FILTER);
  const { startDate, endDate } = useSelector(getDateFilterValue);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (cookie) {
      setIsOpen(false);
      return;
    }

    if (!startDate) {
      setIsOpen(true);
      return;
    }

    setIsOpen(false);
  }, [cookie, startDate, endDate]);

  const handleOnClose = React.useCallback(() => {
    updateCookie('accepted', {
      expires: dayjs().add(1, 'day').toDate(),
    });

    setIsOpen(false);
  }, [updateCookie]);

  const handleOnClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      const searchAnchor = document.getElementById('search-header-button');

      searchAnchor?.click();

      setTimeout(() => {
        const dateAnchor = document.getElementById('search-modal-date-input');
        dateAnchor?.click();
      }, 500);

      handleOnClose();
    },
    [handleOnClose],
  );

  if (isOpen) {
    return (
      <Alert
        variant="standard"
        icon={md ? <Calendar className={classes.icon} /> : false}
        severity="info"
        onClose={handleOnClose}
        className={classes.container}
      >
        <Link to="" kind="mint-underline" onClick={handleOnClick}>
          {T('search.info_bar.add_dates_link')}
        </Link>
        {` ${T('search.info_bar.add_dates_why')}`}
      </Alert>
    );
  }

  return null;
};
